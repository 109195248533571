<template>
    <div class="datasoures">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader  @excelExportData="excelExportData" @handFromshow="handFromshow"></Tabheader>
        </div>
        
        <div class="fromshea" v-show="iShow">
            <el-form :inline="true" size="small"  :model="searchParams" class="demo-form-inline">
                <el-form-item  :label="$t('devtable.bm')">
                    <Select @handleCheckChange="handleCheckChange"></Select>
                </el-form-item>
                <el-form-item :label="$t('devtable.xm')">
                    <el-input v-model="searchParams.name" :placeholder="$t('devtable.xm')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.ghHH')">
                    <el-input v-model="searchParams.number" :placeholder="$t('devtable.ghHH')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.qsrq')">
                    <el-date-picker type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd" 
                    :placeholder="$t('devtable.xzrq')" 
                    v-model="searchParams.start_time" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('devtable.jsrq')">
                    <el-date-picker type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" 
                     :placeholder="$t('devtable.xzrq')" v-model="searchParams.end_time" style="width: 100%;"></el-date-picker>
                </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
            </el-form-item>
        </el-form>
        </div>
        <Tables  @handlpages="handlpages" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs"></Tables>
        
    </div>
</template>
<script>
//考勤日报
import Tables from '@/components/table/tables'
import Tabheader from './components/hebut'
import Select from '@/components/selectree/selectchoice'
import {attendanceServer} from '../../api/uesr'
import methodlist from '../../utils/methods'
import {formatDate,timestampToTime} from '../../utils/date'
import xlxs from 'xlsx'

export default {
    components:{
        Tables,
        Tabheader,
        Select
    },
    data(){
        return{
            iShow:false,
            searchParams: {
                dept_id: '',
                name: '',
                number: '',
                start_time: '',
                end_time: ''
            },
            centerDialogVisible: false,
            
            tableData: [
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
           UserInfo:'',
           loading:''
        }
    },
    computed:{
        tableLabel: function(){
            return[
                {
                    prop: 'date',
                    label: this.$t('devtable.skrq'),
                    align: 'center',
                    width:100
                },
                {
                    prop: 'user_name',
                    label: this.$t('devtable.xm'),
                    align: 'center'
                },               
                {
                    prop: 'user_number',
                    label: this.$t('devtable.ghHH'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bm'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'late',
                    label: this.$t('devtable.cdfz'),
                    align: 'center',
					width:90
                },
                {
                    prop: 'leave',
                    label: this.$t('devtable.ztfz'),
                    align: 'center',
					width:110
                },{
                    prop: 'overtime',
                    label: this.$t('devtable.jbfz'),
                    align: 'center',
					width:90
                },{
                    prop: 'time1_start',
                    label: this.$t('devtable.sk')+1,
                    align: 'center',
					width:110
                },{
                    prop: 'time1_end',
                    label: this.$t('devtable.sk')+2,
                    align: 'center',
					width:110
                },{
                    prop: 'time2_start',
                    label: this.$t('devtable.sk')+3,
                    align: 'center',
					width:110
                },{
                    prop: 'time2_end',
                    label: this.$t('devtable.sk')+4,
                    align: 'center',
					width:110
                },{
                    prop: 'time3_start',
                    label: this.$t('devtable.sk')+5,
                    align: 'center',
					width:110
                },{
                    prop: 'time3_end',
                    label: this.$t('devtable.sk')+6,
                    align: 'center',
					width:110
                },
            ]
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getAttendanceDayRecordList(1);
    },
    methods:{
        handleCheckChange(val){
            this.searchParams.dept_id = val;
        },           
        handRegister(){
            this.centerDialogVisible=true
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        onSubmit() {
            console.log('submit!');
            this.getAttendanceDayRecordList(1, this.searchParams.dept_id,this.searchParams.name,this.searchParams.number,
this.searchParams.start_time,this.searchParams.end_time);
        },
        handlpages(page){
            this.getAttendanceDayRecordList(page);
        },
        getAttendanceDayRecordList(pageIndex,dept_id,name,number,date_start,date_end){
            const params = {
                method: 'att.report.day.get',
                agent_id: this.UserInfo.agent_id,
                manager_id: this.UserInfo.manager_id,
                page_index: pageIndex?pageIndex:1, 
                page_size: this.configs.pagesize,
                dept_id:dept_id?dept_id: this.searchParams.dept_id,                
                name: name?name:this.searchParams.name,
                number:number?number: this.searchParams.number,
                date_start:date_start?date_start: this.searchParams.start_time,
                date_end:date_end?date_end: this.searchParams.end_time
            }

            attendanceServer(params).then(res => {
                console.log(res);
                this.tableData = res.data.data.records;
                this.tableData.forEach(el=>{
                    if(el.unlock_state == 0){
                        el.unlock_state_hint={
                        name: this.$t('devtable.j'),
                        type: 'primary',
                        state: true
                        }
                    }else{
                        el.unlock_state_hint={
                        name: this.$t('devtable.c'),
                        type: 'primary',
                        state: true
                        }
                    }
                    //el.remark = '备注'
                })
                if(params.page_index==1){
                    this.configs.total = res.data.data.recordCount;
                }
                
            })
        },
        //分页获取全部数据
        excelExportData(){
            
            this.loading = this.$loading({ //加载层
                lock: true,
                text: "正在导出中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.6)"
            });
            var exportData = [];
            var pageData = [];
            var allDataTimer=null;
            var postFlag = false;
            var record_index = 1;
            var record_size = 10;
            var data_count = 0;
            allDataTimer = setInterval(()=>{
                if(postFlag == false){
                    postFlag=true;
                    const params = {
                        method: 'att.report.day.get',
                        agent_id: this.UserInfo.agent_id,
                        manager_id: this.UserInfo.manager_id,
                        page_index: record_index, 
                        page_size: record_size,
                        dept_id: this.searchParams.dept_id,                
                        name: this.searchParams.name,
                        number: this.searchParams.number,
                        date_start: this.searchParams.start_time,
                        date_end: this.searchParams.end_time
                    }

                    attendanceServer(params).then(res => {
                        console.log(res);
                        postFlag = false;
                        
                        pageData = res.data.data.records;
                        exportData= exportData.concat(pageData);
                        pageData.forEach(el=>{
                            if(el.unlock_state == 0){
                                el.unlock_state_hint={
                                name: this.$t('devtable.j'),
                                type: 'primary',
                                state: true
                                }
                            }else{
                                el.unlock_state_hint={
                                name: this.$t('devtable.c'),
                                type: 'primary',
                                state: true
                                }
                            }
                        })
                        console.log(`yyy`,res.data.data.recordCount)
                        if(record_index == 1)
                            data_count = res.data.data.recordCount
                        
                        if(record_index * record_size >=data_count){
                            console.log(`zzzz`,record_index ,record_size)
						    clearInterval(allDataTimer);
                            allDataTimer = null;
                            console.log(exportData);
                            this.excelExportDataSnd(exportData);
						}else{
                            record_index+=1;
                            console.log(`kkk`,record_index)
                        }
                        
                    })                    
                }
            },500)
        }, 
        excelExportDataSnd(tableData){
           
            this.loading.close(); //关闭弹窗
            let arr = tableData.map(item => {
                if(window.localStorage.getItem('language')=='en'){
                    return {
                        'Date':item.date,
                        'Name':item.user_name,
                        'Job number': item.user_number,
                        'Department':item.dept_name,
                        'Late':item.late,
                        'Leave early':item.leave,
                        'work overtime':item.overtime,
                        'pay by card1': item.time1_start,
                        'pay by card2': item.time1_end,
                        'pay by card3': item.time2_start,
                        'pay by card4': item.time2_end,
                        'pay by card5': item.time3_start,
                        'pay by card6': item.time3_end
                    }
                }else{
                    return {
                        '日期':item.date,
                        '姓名':item.user_name,
                        '工号': item.user_number,
                        '部门':item.dept_name,
                        '迟到':item.late,
                        '早退':item.leave,
                        '加班':item.overtime,
                        '刷卡1': item.time1_start,
                        '刷卡2': item.time1_end,
                        '刷卡3': item.time2_start,
                        '刷卡4': item.time2_end,
                        '刷卡5': item.time3_start,
                        '刷卡6': item.time3_end
                    }
                }
                
            })
            // 将数据转换为表格
            let sheet = xlxs.utils.json_to_sheet(arr)
            let book = xlxs.utils.book_new()
            xlxs.utils.book_append_sheet(book, sheet, "sheet1")
         
            xlxs.writeFile(book, this.$t('navLeft.kqrb')+'-'+`${timestampToTime()}.xls`)            
        }                
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    
    .fromshea{
        margin-top:20px;
        padding: 20px;
       
    }
}
.tabletemplate{
    padding: 0px;
    padding-top:20px ;
    
}

</style>