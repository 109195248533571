<template>
    <el-select v-model="mineStatus" :placeholder="$t('devtable.qxz')" size="small" multiple>
        <el-option :value="mineStatus"  style="height: auto">
            <el-tree
                default-expand-all
                :data="treedata"
                check-strictly
                show-checkbox
                node-key="id"
                :default-checked-keys="initIdList"
                ref="tree" highlight-current :props="defaultProps"
                @check-change="handleCheckChange"
            >
            </el-tree>

        </el-option>
    </el-select>
</template>
<script>
import methodlist from '@/utils/methods'
import {Qiyebum} from '@/utils/index'

export default {
    data(){
        return{
            mineStatus: "",
            treedata: [],
            defaultProps: {
                children: "children",
                label: "name",
                value:'id',
                disabled: "is_valid"
            },
            deptData: [],
            initNameList: [],
            initIdList: [],
            UserInfo:''
        }
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getDeptDataList()
    },
    methods:{
        //获取企业架构
        getDeptDataList(){
            const params = {
                method: methodlist.ee_dept_list,
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
            }
            this.$serve(params).then(res => {       
                var result = [];
                if(res.data.data != null)
                    result.push(this.getFirstObj(res.data.data))
                this.deptData = result;
                //console.log(`6788`,result)
                this.treedata=Qiyebum(result)
                //this.setInitSelected(0,"市场部")
            });
        },
        getFirstObj(obj){
            for(var key in obj){
                return obj[key];
            }      
        },
        //获取企业部门id
        handleCheckChange(data, checked, node) {
            let res = this.$refs.tree.getCheckedNodes(false, true); //true，1. 是否只是叶子节点 2.选择的时候不包含父节点）
            if (checked) {
                this.$refs.tree.setCheckedNodes([data]);
            }
            let arrLabel = [];
            let dept_idlist = []
            
            res.forEach(item => {
                arrLabel.push(item.name)
                dept_idlist.push(item.id)
                console.log(item)
            });
            this.mineStatus = arrLabel;
            this.dept_id = res.id
            console.log(dept_idlist.toString(), '12')
            this.$emit('handleCheckChange',dept_idlist.toString())
        },

        //根据部门名字获取id
        getDeptIdWithName(name,object){
            for(var i in object){
                if(name == object[i].name){
                    this.initIdList.push(object[i].id)
                    this.initNameList.push(object[i].name)
                    return false;
                }
                if(object[i].children){
                    this.getDeptIdWithName(name,object[i].children);
                }
            }
        },
        //根据部门id获取部门名称
        getDeptNameWithId(id,object){
            for(var i in object){
                if(id == object[i].id){
                    this.initIdList.push(object[i].id)
                    this.initNameList.push(object[i].name)
                    return false;
                }
                if(object[i].children){
                    this.getDeptNameWithId(id,object[i].children);
                }
            }
        },
        //重新获取数据并设置选中
        setInitSelected(type,setData){           
            const params = {
                method: methodlist.ee_dept_list,
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
            }
            this.$serve(params).then(res => {       
                var tempRes = [];
                if(res.data.data != null)
                    tempRes.push(this.getFirstObj(res.data.data))

                this.initIdList = []
                this.initNameList = []
                if(type == 0)
                    this.getDeptIdWithName(setData,tempRes)
                else
                    this.getDeptNameWithId(setData,tempRes)
                if(this.initNameList.length != 0){
                    this.mineStatus = this.initNameList;       
                }                
            });
        },
        //设置选中
        setInitSelection(type,setData){
            this.initIdList = []
            this.initNameList = []            
            if(type == 0)
                this.getDeptIdWithName(setData,this.deptData)
            else
                this.getDeptNameWithId(setData,this.deptData)
            if(this.initNameList.length != 0){
                this.mineStatus = this.initNameList;       
            }                 
        }

    }
}
</script>
<style scoped>
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
     background-color: #fff;
}
</style>