<template>
  <div class="headerbut" style="margin-left:20px">
        <div @click="handFromshow" style="margin-right:20px"><el-input
        class="dev_dowm"    
        :placeholder="$t('devtable.qdjcx')"
        suffix-icon="el-icon-arrow-down"
        size="small"
        :readonly="true"
        >
        </el-input></div>
        <el-button type="primary" size="small" @click="excelExportData">{{$t('devtable.dc')}}</el-button>
    </div>
</template>

<script>
export default {
    data(){
        return{}
    },
    methods:{
        handFromshow(){
            this.$emit('handFromshow')
        },
        excelExportData(){
            this.$emit('excelExportData')
        }
    }
}
</script>

<style>
.headerbut{
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
}
</style>